<template>
	<div v-if="RelationImageList.length > 0 && $UserID">
		<div class="listtitle_box m_t20 related_title">
			<i></i>
			<img src="../../images/xgtp_ico.png">
			<!-- <div class="xg_more">
				<a href="javascript:void(0)">
					<span>更多</span>
					<em>></em>
				</a>
			</div> -->
		</div>
		<div class="xgts_box detail_xgtsbox">
			<ul class="resource_imageRelated1">
				<li v-for="(value,index) in RelationImageList" :key="index" v-show="index<2" @click="toImageDetail(value)">
					<div class="image_cover">
						<img  :src="$ImgUrl + value.CoverPath" :onerror="defaultImg" />
					</div>
					<div class="image_content">
						<h3>{{value.Name}}</h3>
						<span>图片尺寸：{{value.image_width}}*{{value.image_height}}</span>
						<span>图片格式：{{value.image_format}}</span>
						<span>图片大小：{{value.image_file_size/1024 | toFixed(2)}}kb</span>
					</div>
				</li>
			</ul>
			<ul class="resource_imageRelated2">
				<li v-for="(value,index) in RelationImageList.slice(2)" :key="index">
					<div class="xgts_boxt">
						<a href="javascript:void(0)" @click="toImageDetail(value)">
							<img :src="$ImgUrl + value.CoverPath" :onerror="defaultImg" />
						</a>
					</div>
					<div class="xgts_boxb">
						<a href="javascript:void(0)" @click="toImageDetail(value)">
							<h3>{{value.Name}}</h3>
						</a>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import CheckInService from '../../api/CheckInService.js'
	export default{
		data(){
			return{
				defaultImg: 'this.src="' + require('../../images/picture1.jpg') + '"', //默认图片
				RelationImageList:[],
			}
		},
		props: ['relatedType','topNum','resID'],
		methods:{
			GetRelationImageList(){
				var that = this
				CheckInService.GetRelationImageList(that.relatedType,that.resID,that.topNum).then(res=>{
					if(res.Tag == 1){
						that.RelationImageList = res.Result?res.Result:[]
					}
				})
			},
			toImageDetail(value){ //词条详情
				window.open(this.$WebUrl + 'ImageDetail?id=' + value.ID)
			}
		},
		mounted(){
			this.GetRelationImageList()
		}
	}
</script>

<style>
</style>
