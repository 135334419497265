<template>
	<div v-if="RelationAudioList.length > 0 && $UserID">
		<div class="listtitle_box m_t20 related_title">
			<i></i>
			<img src="../../images/xgyp_ico.png">
			<!-- <div class="xg_more">
				<a href="javascript:void(0)">
					<span>更多</span>
					<em>></em>
				</a>
			</div> -->
		</div>
		<div class="xgyp_box detailxgyp_box resource_audioRelated">
			<ul>
				<li v-for="(value,index) in RelationAudioList" :key="index">
					<a href="javascript:void(0)" @click="GetMediaModel(value.ID)" class="audio_cover">
						<img v-if="!value.CoverPath" src="../../images/audio_cover.png" />
						<img v-if="value.CoverPath" :src="$ImgUrl + value.CoverPath" />
					</a>
					<a href="javascript:void(0)" @click="GetMediaModel(value.ID)">
						<div class="audio_content">
							<h2 :title="value.Name">{{value.Name | GetSubstring(12)}}</h2>
							<span>{{value.Time}}</span>
						</div>
					</a>
				</li>
			</ul>
		</div>
		<div class="video_player" v-show="showPlayer">
			<div class="video_player_c">
				<div class="dltc_box1_maint">
					<h4>音频播放</h4>
					<button class="Pop_btn" style="top: 5px;" @click="hidePlayer">
						<img src="../../images/ts_close.png" />
					</button>
				</div>
				<div class="index_audio">
					<div class="index_audioc">
						<div class="index_audioc_l">
							<img v-if="!audioInfo.CoverPath" src="../../images/audio_cover.png"/>
							<img v-if="audioInfo.CoverPath" :src="$ImgUrl + audioInfo.CoverPath"/>
						</div>
						<div class="index_audioc_r">
							<h3 :title="audioInfo.ResName">{{audioInfo.ResName}}</h3>
							<span v-if="audioInfo.Time">时长：{{audioInfo.Time}}</span>
						</div>
					</div>
					<audio ref="player" controlsList="nodownload" :src="audioFilePath" controls="controls"></audio>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CheckInService from '../../api/CheckInService.js'
	export default{
		data(){
			return{
				RelationAudioList:[],
				audioFilePath:'',
				showPlayer:false,
				audioInfo:''
			}
		},
		props: ['relatedType','topNum','resID'],
		methods:{
			GetRelationAudioList(){
				var that = this
				CheckInService.GetRelationAudioList(that.relatedType,that.resID,that.topNum).then(res=>{
					if(res.Tag == 1){
						that.RelationAudioList = res.Result?res.Result:[]
					}
				})
			},
			GetMediaModel(id){ //获取音视频信息
				var that = this
				CheckInService.GetMediaModel(id,that.$UserID).then(res=>{
					if(res.Tag == 1){
						that.audioInfo = res.Result.MediaModel
						that.audioFilePath = that.$ImgUrl + res.Result.MediaModel.ResPath
						that.showPlayer = true
					}
				})
			},
			hidePlayer(){
				this.showPlayer = false
				this.$refs.player.pause()
			}
		},
		created(){
			this.GetRelationAudioList()
		}
	}
</script>

<style scoped="scoped">
	.audio_cover{ border: 2px solid #eaddd4; width: 100%; height: 126px; box-sizing: border-box; text-align: center; line-height: 122px;}
	.audio_cover img{ width: auto !important; height: auto !important; border: none; vertical-align: middle; max-width: 100%; max-height: 100%;}
	.video_player{ position: fixed; z-index: 1000000; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.5); display: flex; justify-content: space-around; align-items: center;}
	.video_player_c{ width: 400px; min-height: 160px; background: #fff; box-sizing: border-box; overflow: hidden;}
	.video_player_c .dltc_box1_maint{ width: 100%;}
	.video_player_c audio{ margin: 50px 40px 0; width: calc( 100% - 80px ); min-height: 30px; display: block !important; outline: none;}
</style>
