<template>
	<div v-if="RelationVideoList.length > 0 && $UserID">
		<div class="listtitle_box m_t20 related_title">
			<i></i>
			<img src="../../images/xgsp_ico.png">
			<!-- <div class="xg_more">
				<a href="javascript:void(0)">
					<span>更多</span>
					<em>></em>
				</a>
			</div> -->
		</div>
		<div class="xgyp_box detailxgyp_box m_b20">
			<ul>
				<li v-for="(value,index) in RelationVideoList" :key="index">
					<a href="javascript:void(0)" @click="GetMediaModel(value.ID)">
						<img :src="$ImgUrl + value.CoverPath" :onerror="defaultImg" />
					</a>
					<a href="javascript:void(0)" @click="GetMediaModel(value.ID)">
						<div class="video_content">
							<h2 :title="value.Name">{{value.Name}}</h2>
							<span>{{value.Time}}</span>
						</div>
					</a>
				</li>
			</ul>
		</div>
		<div class="video_player" v-show="showPlayer">
			<div class="video_player_c">
				<div class="dltc_box1_maint">
					<h4>视频播放</h4>
					<button class="Pop_btn" style="top: 5px;" @click="hidePlayer">
						<img src="../../images/ts_close.png" />
					</button>
				</div>
				<div class="play_layerc1" v-if="videoFilePath && videoFilePath.indexOf('<iframe') == -1">
					<video ref="videoPlayer" autoplay="autoplay" :src="$ImgUrl + videoFilePath" :poster="$ImgUrl+CoverPath" controls="controls" controlsList="nodownload"></video>
				</div>
				<div class="play_layerc1" v-if="videoFilePath && videoFilePath.indexOf('<iframe') != -1" v-html="videoFilePath"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import CheckInService from '../../api/CheckInService.js'
	export default{
		data(){
			return{
				defaultImg: 'this.src="' + require('../../images/picture1.jpg') + '"', //默认图片
				RelationVideoList:[],
				videoFilePath:'',//音频路径
				CoverPath:'',//封面
				showPlayer:false
			}
		},
		props: ['relatedType','topNum','resID'],
		methods:{
			GetRelationVideoList(){
				var that = this
				CheckInService.GetRelationVideoList(that.relatedType,that.resID,that.topNum).then(res=>{
					if(res.Tag == 1){
						that.RelationVideoList = res.Result?res.Result:[]
					}
				})
			},
			GetMediaModel(id){ //获取音视频信息
				var that = this
				CheckInService.GetMediaModel(id,that.$UserID).then(res=>{
					if(res.Tag == 1){
						that.videoFilePath = res.Result.MediaModel.ResPath
						that.CoverPath = res.Result.MediaModel.CoverPath
						that.showPlayer = true
					}
				})
			},
			hidePlayer(){
				this.showPlayer = false
				this.$refs.videoPlayer.pause()
			}
		},
		mounted() {
			this.GetRelationVideoList()
		}
	}
</script>

<style scoped="scoped">
	.video_player{ position: fixed; z-index: 1000000; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.5); display: flex; justify-content: space-around; align-items: center;}
	.video_player_c{ float: left; width: 1200px; background: #fff;}
	.video_player_c .dltc_box1_maint{ width: 100%;}
	.play_layerc1{ float: left; width: 100%; padding: 18px; box-sizing: border-box;}
	.play_layerc1 video{ float: left; width: 100%; height: 500px; outline: none;}
	.play_layerc1 iframe{ width: 100%; height: 500px;}
</style>
