<template>
	<div v-if="RelationEntryList.length > 0 && $UserID">
		<div class="listtitle_box m_t20 related_title">
			<i></i>
			<img src="../../images/xgtm_ico.png">
			<!-- <div class="xg_more">
				<a href="javascript:void(0)">
					<span>更多</span>
					<em>></em>
				</a>
			</div> -->
		</div>
		<div class="xglw_box xgtm_box p_b5">
			<ul>
				<li v-for="(value,index) in RelationEntryList" :key="index">
					<a href="javascript:void(0)" @click="toEntryDetail(value)">{{value.Name}}</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import CheckInService from '../../api/CheckInService.js'
	export default{
		data(){
			return{
				RelationEntryList:[]
			}
		},
		props: ['relatedType','topNum','resID'],
		methods:{
			GetRelationEntryList(){ //获取相关条目
				var that = this
				CheckInService.GetRelationEntryList(that.relatedType,that.resID,that.topNum).then(res=>{
					if(res.Tag == 1){
						that.RelationEntryList = res.Result?res.Result:[]
					}
				})
			}, 
			toEntryDetail(value){ //条目详情
				window.open(this.$WebUrl + 'EntryDetail?id=' + value.ID)
			},
			GetEntryRelationEntry(){ //获取条目相关条目
				var that = this
				CheckInService.GetEntryRelationEntry(that.relatedType,that.resID,that.topNum).then(res=>{
					if(res.Tag == 1){
						that.RelationEntryList = res.Result?res.Result:[]
					}
				})
			}
		},
		mounted(){
			if(this.relatedType == 1){
				this.GetEntryRelationEntry()
			}else{
				this.GetRelationEntryList()
			}
			
		}
	}
</script>

<style>
</style>
