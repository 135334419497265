<template>
	<div v-if="RelationBookList.length > 0 && $UserID">
		<div class="listtitle_box m_t20 related_title">
			<i></i>
			<img src="../../images/xgts_ico.png">
			<!-- <div class="xg_more">
				<a href="javascript:void(0)">
					<span>更多</span>
					<em>></em>
				</a>
			</div> -->
		</div>
		<div class="detail_xgts resource_bookRelated">
			<ul class="resource_bookRelated1">
				<li v-for="(value,index) in RelationBookList" :key="index" v-show="index < 6">
					<h3 :title="value.Name" @click="toBookDetail(value)">{{value.Name}}</h3>
				</li>
			</ul>
			<ul class="resource_bookRelated2">
				<li v-for="(value,index) in RelationBookList.slice(6)" :key="index">
					<div class="detail_xgts1">
						<a href="javascript:void(0)" @click="toBookDetail(value)">
							<img :src="$ImgUrl + value.CoverPath " :onerror="defaultImg" />
						</a>
					</div>
					<div class="detail_xgts2">
						<a href="javascript:void(0)" @click="toBookDetail(value)">
							<h3>{{value.Name | GetSubstring(15)}}</h3>
						</a>
						<p>{{value.Author}}</p>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import CheckInService from '../../api/CheckInService.js'
	export default{
		data(){
			return{
				defaultImg: 'this.src="' + require('../../images/picture1.jpg') + '"', //默认图片
				RelationBookList:[]
			}
		},
		props: ['relatedType','topNum','resID'],
		methods:{
			GetRelationBookList(){
				var that = this
				CheckInService.GetRelationBookList(that.relatedType,that.resID,that.topNum).then(res=>{
					if(res.Tag == 1){
						that.RelationBookList = res.Result?res.Result:[]
					}
				})
			},
			toBookDetail(value){ //图书详情
				window.open(this.$WebUrl + 'BookDetail?id=' + value.ID)
			}
		},
		mounted(){
			this.GetRelationBookList()
		}
	}
</script>

<style>
</style>
