<template>
	<div v-if="RelationElementList.length > 0 && $UserID">
		<div class="listtitle_box m_t20 related_title">
			<i></i>
			<img src="../../images/xgzsy_ico.png">
			<!-- <div class="xg_more">
				<a href="javascript:void(0)">
					<span>更多</span>
					<em>></em>
				</a>
			</div> -->
		</div>
		<div class="detail_xgzsy">
			<ul>
				<li v-for="(value,index) in RelationElementList" :key="index">
					<a href="javascript:void(0)" @click="toElementDetail(value)">
						<p><span>【{{value.Name}}】</span>{{value.Annotation}}</p>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import CheckInService from '../../api/CheckInService.js'
	export default{
		data(){
			return{
				defaultImg: 'this.src="' + require('../../images/picture1.jpg') + '"', //默认图片
				RelationElementList:[]
			}
		},
		props: ['relatedType','topNum','resID'],
		methods:{
			GetRelationElementList(){
				var that = this
				CheckInService.GetRelationElementList(that.relatedType,that.resID,that.topNum).then(res=>{
					if(res.Tag == 1){
						that.RelationElementList = res.Result?res.Result:[]
					}
				})
			},
			toElementDetail(value){ //词条详情
				window.open(this.$WebUrl + 'ElementDetail?id=' + value.ID)
			}
		},
		mounted() {
			this.GetRelationElementList()
		}
	}
</script>

<style>
</style>
