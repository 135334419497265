<template>
	<div>
		<div class="layer_con" :style="'height:'+LayerHeight+'px'">
			<div class="layer_loginbtn" v-if="!$UserID">您还未登录，请<span @click="toLogin()">立即登录</span></div>
			<div class="layer_loginbtn layer_vipbtn" v-if="$UserID && $ServiceType != 1">请联系平台管理员开通VIP服务</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				showLogin:false
			}
		},
		methods:{
			toLogin(){ //登录
				this.$parent.toLogin()
			}
		},
		props: ['LayerHeight'],
	}
</script>

<style scoped="scoped">
	
</style>
